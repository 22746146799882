/* eslint max-lines: 0 */

/**
 * The id must match the id defined the following files:
 * /constants/i18n/subjects.js
 * /mocks/fetch/v1/public/subjects.json
 */

export const AIDINKIELI = {
  id: 'äidinkieli',
  name: 'Äidinkieli',
}

export const AIDINKIELI_RUOTSI_LUKUTAIDON_KOE = {
  id: 'äidinkieli-ruotsi-lukutaidon-koe',
  name: 'Äidinkieli, ruotsi, lukutaidon koe',
}

export const AIDINKIELI_RUOTSI_ESSEE = {
  id: 'äidinkieli-ruotsi-essee',
  name: 'Äidinkieli, ruotsi, esseekoe',
}

export const AIDINKIELI_RUOTSI_TEKSTITAITO = {
  id: 'äidinkieli-ruotsi-tekstitaito',
  name: 'Äidinkieli, ruotsi, tekstitaidon koe',
}

export const AIDINKIELI_SUOMI_LUKUTAIDON_KOE = {
  id: 'äidinkieli-suomi-lukutaidon-koe',
  name: 'Äidinkieli, suomi, lukutaidon koe',
}

export const AIDINKIELI_SUOMI_ESSEE = {
  id: 'äidinkieli-suomi-essee',
  name: 'Äidinkieli, suomi, esseekoe',
}

export const AIDINKIELI_SUOMI_TEKSTITAITO = {
  id: 'äidinkieli-suomi-tekstitaito',
  name: 'Äidinkieli, suomi, tekstitaidon koe',
}

export const BIOLOGIA = {
  id: 'biologia',
  name: 'Biologia',
}

export const ELAMANKATSOMUSTIETO = {
  id: 'elämänkatsomustieto',
  name: 'Elämänkatsomustieto',
}

export const ENGLANTI = {
  id: 'englanti',
  name: 'Englanti',
}

export const ENGLANTI_LYHYT = {
  id: 'englanti-lyhyt',
  name: 'Englanti, lyhyt oppimäärä',
}

export const ENGLANTI_PITKA = {
  id: 'englanti-pitkä',
  name: 'Englanti, pitkä oppimäärä',
}

export const ESPANJA = {
  id: 'espanja',
  name: 'Espanja',
}

export const ESPANJA_LYHYT = {
  id: 'espanja-lyhyt',
  name: 'Espanja, lyhyt oppimäärä',
}

export const ESPANJA_PITKA = {
  id: 'espanja-pitkä',
  name: 'Espanja, pitkä oppimäärä',
}

export const FILOSOFIA = {
  id: 'filosofia',
  name: 'Filosofia',
}

export const FYSIIKKA = {
  id: 'fysiikka',
  name: 'Fysiikka',
}

export const HISTORIA = {
  id: 'historia',
  name: 'Historia',
}

export const ITALIA = {
  id: 'italia',
  name: 'Italia',
}

export const ITALIA_LYHYT = {
  id: 'italia-lyhyt',
  name: 'Italia, lyhyt oppimäärä',
}

export const KASITYO = {
  id: 'käsityö',
  name: 'Käsityö',
}

export const KEMIA = {
  id: 'kemia',
  name: 'Kemia',
}

export const KOTITALOUS = {
  id: 'kotitalous',
  name: 'Kotitalous',
}

export const KUVATIEDE = {
  id: 'kuvatiede',
  name: 'Kuvatiede',
}

export const LATINA = {
  id: 'latina',
  name: 'Latina',
}

export const LATINA_LYHYT = {
  id: 'latina-lyhyt',
  name: 'Latina, lyhyt oppimäärä',
}

export const LATINA_PIDEMPI = {
  id: 'latina-pidempi',
  name: 'Latina, pidempi oppimäärä',
}

export const MAANTIEDE = {
  id: 'maantiede',
  name: 'Maantiede',
}

export const MATEMATIIKKA = {
  id: 'matematiikka',
  name: 'Matematiikka',
}

export const MATEMATIIKKA_LYHYT = {
  id: 'matematiikka-lyhyt',
  name: 'Matematiikka, lyhyt oppimäärä',
}

export const MATEMATIIKKA_PITKA = {
  id: 'matematiikka-pitkä',
  name: 'Matematiikka, pitkä oppimäärä',
}

export const MUSIIKKI = {
  id: 'musiikki',
  name: 'Musiikki',
}

export const MUU = {
  id: 'muu',
  name: 'Muu',
}

export const PORTUGALI = {
  id: 'portugali',
  name: 'Portugali',
}

export const PORTUGALI_LYHYT = {
  id: 'portugali-lyhyt',
  name: 'Portugali, lyhyt oppimäärä',
}

export const PSYKOLOGIA = {
  id: 'psykologia',
  name: 'Psykologia',
}

export const RANSKA = {
  id: 'ranska',
  name: 'Ranska',
}

export const RANSKA_LYHYT = {
  id: 'ranska-lyhyt',
  name: 'Ranska, lyhyt oppimäärä',
}

export const RANSKA_PITKA = {
  id: 'ranska-pitkä',
  name: 'Ranska, pitkä oppimäärä',
}

export const RUOTSI = {
  id: 'ruotsi',
  name: 'Ruotsi',
}

export const RUOTSI_KESKIPITKA = {
  id: 'ruotsi-keskipitkä',
  name: 'Ruotsi, keskipitkä oppimäärä',
}

export const RUOTSI_PITKA = {
  id: 'ruotsi-pitkä',
  name: 'Ruotsi, pitkä oppimäärä',
}

export const RUOTSI_TOISENA = {
  id: 'ruotsi-toisena',
  name: 'Ruotsi toisena kielenä',
}

export const SAAME_INARI = {
  id: 'saame-inari',
  name: 'Inarinsaame',
}

export const SAAME_INARI_AIDINKIELI_JA_KIRJALLISUUS = {
  id: 'saame-inari-aidinkieli-ja-kirjallisuus',
  name: 'Inarinsaame, äidinkieli ja kirjallisuus',
}

export const SAAME_INARI_LYHYT = {
  id: 'saame-inari-lyhyt',
  name: 'Inarinsaame, lyhyt oppimäärä',
}

export const SAAME_KOLTA = {
  id: 'saame-kolta',
  name: 'Koltansaame',
}

export const SAAME_KOLTA_LYHYT = {
  id: 'saame-kolta-lyhyt',
  name: 'Koltansaame, lyhyt oppimäärä',
}

export const SAAME_POHJOIS = {
  id: 'saame-pohjois',
  name: 'Pohjoissaame',
}

export const SAAME_POHJOIS_AIDINKIELI_JA_KIRJALLISUUS = {
  id: 'saame-pohjois-aidinkieli-ja-kirjallisuus',
  name: 'Pohjoissaame, äidinkieli ja kirjallisuus',
}

export const SAAME_POHJOIS_LYHYT = {
  id: 'saame-pohjois-lyhyt',
  name: 'Pohjoissaame, lyhyt oppimäärä',
}

export const SAKSA = {
  id: 'saksa',
  name: 'Saksa',
}

export const SAKSA_LYHYT = {
  id: 'saksa-lyhyt',
  name: 'Saksa, lyhyt oppimäärä',
}

export const SAKSA_PITKA = {
  id: 'saksa-pitkä',
  name: 'Saksa, pitkä oppimäärä',
}

export const SUOMI_KESKIPITKA = {
  id: 'suomi-keskipitkä',
  name: 'Suomi, keskipitkä oppimäärä',
}

export const SUOMI_PITKA = {
  id: 'suomi-pitkä',
  name: 'Suomi, pitkä oppimäärä',
}

export const SUOMI_TOISENA = {
  id: 'suomi-toisena',
  name: 'Suomi toisena kielenä',
}

export const TERVEYSTIETO = {
  id: 'terveystieto',
  name: 'Terveystieto',
}

export const USKONTO_EVLUT = {
  id: 'uskonto-evlut',
  name: 'Evankelisluterilainen uskonto',
}

export const USKONTO_ORTOD = {
  id: 'uskonto-ortodoksinen',
  name: 'Ortodoksinen uskonto',
}

export const VENAJA = {
  id: 'venäjä',
  name: 'Venäjä',
}

export const VENAJA_LYHYT = {
  id: 'venäjä-lyhyt',
  name: 'Venäjä, lyhyt oppimäärä',
}

export const VENAJA_PITKA = {
  id: 'venäjä-pitkä',
  name: 'Venäjä, pitkä oppimäärä',
}

export const YHTEISKUNTAOPPI = {
  id: 'yhteiskuntaoppi',
  name: 'Yhteiskuntaoppi',
}

export const NAKOVAMMAISET_ENGLANTI_LYHYT = {
  id: 'näkövammaiset-englanti-lyhyt',
  name: 'Näkövammaiset, englanti, lyhyt oppimäärä',
}
export const NAKOVAMMAISET_RANSKA_LYHYT = {
  id: 'näkövammaiset-ranska-lyhyt',
  name: 'Näkövammaiset, ranska, lyhyt oppimäärä',
}
export const NAKOVAMMAISET_MATEMATIIKKA_LYHYT = {
  id: 'näkövammaiset-matematiikka-lyhyt',
  name: 'Näkövammaiset, matematiikka, lyhyt oppimäärä',
}
export const NAKOVAMMAISET_MATEMATIIKKA_PITKA = {
  id: 'näkövammaiset-matematiikka-pitkä',
  name: 'Näkövammaiset, matematiikka, pitkä oppimäärä',
}
export const NAKOVAMMAISET_YHTEISKUNTAOPPI = {
  id: 'näkövammaiset-yhteiskuntaoppi',
  name: 'Näkövammaiset, yhteiskuntaoppi',
}
export const NAKOVAMMAISET_RUOTSI_KESKIPITKA = {
  id: 'näkövammaiset-ruotsi-keskipitkä',
  name: 'Näkövammaiset, ruotsi, keskipitkä oppimäärä',
}

export const NAKOVAMMAISET_TERVEYSTIETO = {
  id: 'näkövammaiset-terveystieto',
  name: 'Näkövammaiset, terveystieto',
}

export const NAKOVAMMAISET_PSYKOLOGIA = {
  id: 'näkövammaiset-psykologia',
  name: 'Näkövammaiset, psykologia',
}

export const NAKOVAMMAISET_BIOLOGIA = {
  id: 'näkövammaiset-biologia',
  name: 'Näkövammaiset, biologia',
}

export const NAKOVAMMAISET_ENGLANTI_PITKA = {
  id: 'näkövammaiset-englanti-pitkä',
  name: 'Näkövammaiset, englanti, pitkä oppimäärä',
}

export const NAKOVAMMAISET_AIDINKIELI_KIRJOITUSTAIDON_KOE = {
  id: 'näkövammaiset-äidinkieli-kirjoitustaidon-koe',
  name: 'Näkövammaiset, äidinkieli, kirjoitustaidon koe',
}

export const NAKOVAMMAISET_AIDINKIELI_LUKUTAIDON_KOE = {
  id: 'näkövammaiset-äidinkieli-lukutaidon-koe',
  name: 'Näkövammaiset, äidinkieli, lukutaidon koe',
}

export const NAKOVAMMAISET_MAANTIEDE = {
  id: 'näkövammaiset-maantiede',
  name: 'Näkövammaiset, maantiede',
}

export const NAKOVAMMAISET_HISTORIA = {
  id: 'näkövammaiset-historia',
  name: 'Näkövammaiset, historia',
}

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { fetchSeriesData } from '../../../redux/middleware/seriesService/seriesService'
import { setRootElementId } from '../../../redux/modules/embed/embed'
import SeriesInfo from './SeriesInfo'
import Login from '../../layout/YleTunnus/Login'
import { getSeriesData, getSeriesLoading } from '../../../redux/modules/series/selectors'
import './series-info.scss'
import { getLoggedInState } from '../../../redux/modules/yleTunnus/selectors'
import YleTunnusHoc from '../../misc/YleTunnusHoc/YleTunnusHoc'
import { DARK_MODE_AUTO, DARK_MODE_ON } from '../../../constants/definitions/darkModeParams'

class SeriesInfoContainer extends React.Component {
  componentDidMount() {
    const { seriesIds, fetchSeriesData, setRootElementId, isLoggedIn, hasSeriesIds } = this.props

    setRootElementId()
    if (hasSeriesIds) {
      fetchSeriesData(seriesIds, isLoggedIn)
    }
  }

  componentDidUpdate(prevProps) {
    const { isLoggedIn, seriesIds, hasSeriesIds, fetchSeriesData } = this.props

    if (hasSeriesIds && isLoggedIn !== prevProps.isLoggedIn) {
      fetchSeriesData(seriesIds, isLoggedIn)
    }
  }

  render() {
    const { seriesEmbed, series, loading, collapsible, darkMode } = this.props
    if (!series || series.length === 0) {
      return null
    }

    const containerClassNames = classnames('yo-tehtava', {
      'yo-tehtava--auto-dark': darkMode === DARK_MODE_AUTO,
      'yo-tehtava--force-dark': darkMode === DARK_MODE_ON,
    })

    // Not sure about multiple series settings
    // Check the first series atm
    const { hideTunnusIcon } = series[0].classification
    const content = !loading
      ? series.map(item => (
          <SeriesInfo collapsible={collapsible} darkMode={darkMode} key={item.uuid} series={item} />
        ))
      : null
    return (
      <div className={containerClassNames}>
        {seriesEmbed && !hideTunnusIcon && <Login />}
        {content}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  series: getSeriesData(state),
  loading: getSeriesLoading(state),
  rootElementId: state.embed.rootElementId,
  isLoggedIn: getLoggedInState(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchSeriesData: (uuids, isLoggedIn) => {
    dispatch(fetchSeriesData(uuids, isLoggedIn))
  },
  setRootElementId: () => {
    if (ownProps.rootElementId) {
      dispatch(setRootElementId(ownProps.rootElementId))
    }
  },
  hasSeriesIds: ownProps.seriesIds && ownProps.seriesIds.length > 0,
})
export default withTranslation(['Series'])(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(YleTunnusHoc(SeriesInfoContainer))),
)

import { flatten, uniq, values } from 'ramda'
import moment from 'moment'
import { sortByName } from '../../utils/common'
import * as subject from './subjects'
import { getTranslatedSubjects } from '../../redux/modules/subjects/selectors'

// Exam settings.
export const MATRICULATION_EXAM_TYPE = 'yo-koe'
export const EDUCATION_EXAM_TYPE = 'education-assignment'
export const OTHER_EXAM_TYPE = 'other'
export const TV_EPISODE = 'tv-episode'
export const ENGLISH = 'en-US'
export const FINNISH = 'fi-FI'
export const SWEDISH = 'sv-FI'
export const ARCHTYPE_TEST = 'archtype-test'
export const POLL = 'poll'
export const VOTE = 'vote'
export const REVIEW_ONLY_EXAM_TYPE = 'review-only'

// Default exam settings.
export const DEFAULT_EXAM_LANGUAGE = FINNISH
export const DEFAULT_EXAM_TYPE = OTHER_EXAM_TYPE

export const WRITTEN = 'written'
export const LISTENING_COMPREHENSION = 'listening-comprehension'
export const DIGITAL_EXAM = 'digital-exam'
export const DEFAULT_CATEGORIES = [WRITTEN, LISTENING_COMPREHENSION, DIGITAL_EXAM].join(',')

export const categoryOptions = [
  { id: WRITTEN },
  { id: LISTENING_COMPREHENSION },
  { id: DIGITAL_EXAM },
]

export const examTypeOptions = [
  { id: OTHER_EXAM_TYPE },
  { id: ARCHTYPE_TEST },
  { id: VOTE },
  { id: POLL },
  { id: EDUCATION_EXAM_TYPE },
  { id: MATRICULATION_EXAM_TYPE },
  { id: TV_EPISODE },
  { id: REVIEW_ONLY_EXAM_TYPE },
]

export const languageOptions = [{ id: ENGLISH }, { id: FINNISH }, { id: SWEDISH }]

export const semesterOptions = [{ id: 'spring' }, { id: 'fall' }]

export const rootNodeExamTypes = {
  EXAM_ROOT: DEFAULT_EXAM_TYPE,
  TV_EPISODE_ROOT: TV_EPISODE,
  ARCHTYPE_ROOT: ARCHTYPE_TEST,
  POLL_ROOT: POLL,
  VOTE_ROOT: VOTE,
  REVIEW_ONLY_ROOT: REVIEW_ONLY_EXAM_TYPE,
}

// Common settings for all exam types.
export const commonExamSettings = {
  authors: {},
  forceYletunnus: false,
  language: DEFAULT_EXAM_LANGUAGE,
  published: false,
  hideReviewButtons: false,
  tainted: false,
  showExamSeries: false,
  series_uuids: [],
  hide_statistics: false,
  disable_saving: false,
}

// Settings by exam type.
export const examSettings = {
  [MATRICULATION_EXAM_TYPE]: {
    ...commonExamSettings,
    exam_type: MATRICULATION_EXAM_TYPE,
    category: '',
    semester: '',
    year: '',
  },
  [EDUCATION_EXAM_TYPE]: {
    ...commonExamSettings,
    exam_type: EDUCATION_EXAM_TYPE,
  },
  [OTHER_EXAM_TYPE]: {
    ...commonExamSettings,
    exam_type: OTHER_EXAM_TYPE,
    hideScores: false,
  },
  [ARCHTYPE_TEST]: {
    ...commonExamSettings,
    hideReviewButtons: true,
    hide_statistics: true,
    disable_saving: true,
    exam_type: ARCHTYPE_TEST,
  },
  [TV_EPISODE]: {
    ...commonExamSettings,
    exam_type: TV_EPISODE,
  },
  [POLL]: {
    ...commonExamSettings,
    endTime: moment().add(1, 'months'),
    hideReviewButtons: true,
  },
  [VOTE]: {
    ...commonExamSettings,
    forceYletunnus: true,
    hide_statistics: false,
    disable_saving: false,
    hideReviewButtons: true,
    exam_type: VOTE,
  },
  [REVIEW_ONLY_EXAM_TYPE]: {
    ...commonExamSettings,
    hide_statistics: true,
  },
}

// Subjects shared by Matriculation and Education exams
export const commonSubjectOptions = [
  subject.BIOLOGIA,
  subject.ELAMANKATSOMUSTIETO,
  subject.FILOSOFIA,
  subject.FYSIIKKA,
  subject.HISTORIA,
  subject.KEMIA,
  subject.MAANTIEDE,
  subject.PSYKOLOGIA,
  subject.TERVEYSTIETO,
  subject.USKONTO_EVLUT,
  subject.USKONTO_ORTOD,
  subject.YHTEISKUNTAOPPI,
]

const subjectOptions = {
  [MATRICULATION_EXAM_TYPE]: [
    ...commonSubjectOptions,
    subject.AIDINKIELI_RUOTSI_LUKUTAIDON_KOE,
    subject.AIDINKIELI_RUOTSI_ESSEE,
    subject.AIDINKIELI_RUOTSI_TEKSTITAITO,
    subject.AIDINKIELI_SUOMI_LUKUTAIDON_KOE,
    subject.AIDINKIELI_SUOMI_ESSEE,
    subject.AIDINKIELI_SUOMI_TEKSTITAITO,
    subject.ENGLANTI_LYHYT,
    subject.ENGLANTI_PITKA,
    subject.ESPANJA_LYHYT,
    subject.ESPANJA_PITKA,
    subject.ITALIA_LYHYT,
    subject.LATINA_LYHYT,
    subject.LATINA_PIDEMPI,
    subject.MATEMATIIKKA_LYHYT,
    subject.MATEMATIIKKA_PITKA,
    subject.PORTUGALI_LYHYT,
    subject.RANSKA_LYHYT,
    subject.RANSKA_PITKA,
    subject.RUOTSI_KESKIPITKA,
    subject.RUOTSI_PITKA,
    subject.RUOTSI_TOISENA,
    subject.SAAME_INARI_AIDINKIELI_JA_KIRJALLISUUS,
    subject.SAAME_INARI_LYHYT,
    subject.SAAME_KOLTA_LYHYT,
    subject.SAAME_POHJOIS_AIDINKIELI_JA_KIRJALLISUUS,
    subject.SAAME_POHJOIS_LYHYT,
    subject.SAKSA_LYHYT,
    subject.SAKSA_PITKA,
    subject.SUOMI_KESKIPITKA,
    subject.SUOMI_PITKA,
    subject.SUOMI_TOISENA,
    subject.VENAJA_LYHYT,
    subject.VENAJA_PITKA,
    subject.NAKOVAMMAISET_ENGLANTI_LYHYT,
    subject.NAKOVAMMAISET_ENGLANTI_PITKA,
    subject.NAKOVAMMAISET_RANSKA_LYHYT,
    subject.NAKOVAMMAISET_MATEMATIIKKA_LYHYT,
    subject.NAKOVAMMAISET_MATEMATIIKKA_PITKA,
    subject.NAKOVAMMAISET_YHTEISKUNTAOPPI,
    subject.NAKOVAMMAISET_RUOTSI_KESKIPITKA,
    subject.NAKOVAMMAISET_TERVEYSTIETO,
    subject.NAKOVAMMAISET_PSYKOLOGIA,
    subject.NAKOVAMMAISET_AIDINKIELI_KIRJOITUSTAIDON_KOE,
    subject.NAKOVAMMAISET_AIDINKIELI_LUKUTAIDON_KOE,
    subject.NAKOVAMMAISET_BIOLOGIA,
    subject.NAKOVAMMAISET_MAANTIEDE,
    subject.NAKOVAMMAISET_HISTORIA,
  ],
  [EDUCATION_EXAM_TYPE]: [
    ...commonSubjectOptions,
    subject.AIDINKIELI,
    subject.ENGLANTI,
    subject.ESPANJA,
    subject.ITALIA,
    subject.KASITYO,
    subject.KOTITALOUS,
    subject.KUVATIEDE,
    subject.LATINA,
    subject.MATEMATIIKKA,
    subject.MUSIIKKI,
    subject.MUU,
    subject.PORTUGALI,
    subject.RANSKA,
    subject.RUOTSI,
    subject.SAAME_INARI,
    subject.SAAME_KOLTA,
    subject.SAAME_POHJOIS,
    subject.SAKSA,
    subject.SUOMI_TOISENA,
    subject.VENAJA,
  ],
}

export const subjectsWithCategoryOptions = [
  subject.ENGLANTI_LYHYT,
  subject.ENGLANTI_PITKA,
  subject.ESPANJA_LYHYT,
  subject.ESPANJA_PITKA,
  subject.ITALIA_LYHYT,
  subject.RANSKA_LYHYT,
  subject.RANSKA_PITKA,
  subject.RUOTSI_KESKIPITKA,
  subject.RUOTSI_PITKA,
  subject.RUOTSI_TOISENA,
  subject.SAKSA_LYHYT,
  subject.SAKSA_PITKA,
  subject.SUOMI_KESKIPITKA,
  subject.SUOMI_PITKA,
  subject.VENAJA_LYHYT,
  subject.VENAJA_PITKA,
]

export const subjectsWithMaterialOption = [
  ...commonSubjectOptions,
  subject.AIDINKIELI_RUOTSI_LUKUTAIDON_KOE,
  subject.AIDINKIELI_RUOTSI_ESSEE,
  subject.AIDINKIELI_RUOTSI_TEKSTITAITO,
  subject.AIDINKIELI_SUOMI_LUKUTAIDON_KOE,
  subject.AIDINKIELI_SUOMI_ESSEE,
  subject.AIDINKIELI_SUOMI_TEKSTITAITO,
]

export const subjectsWithTitleEssayOption = [
  subject.AIDINKIELI_RUOTSI_ESSEE,
  subject.AIDINKIELI_SUOMI_ESSEE,
]

export const subjectsWithQuestionTypeOptions = [
  ...subjectsWithCategoryOptions,
  subject.PORTUGALI_LYHYT,
  subject.SUOMI_TOISENA,
  subject.LATINA_LYHYT,
  subject.LATINA_PIDEMPI,
]

// Returns all unique subjects, sorted by the human-readable key `name`
export const getAllSubjectOptions = () => sortByName(uniq(flatten(values(subjectOptions))))

// Returns the translated subjects of a specific exam type, sorted by the human-readable key `name`
export const getSubjectOptions = (examType, language = null) => {
  // Verify that we actually can return something
  if (!subjectOptions[examType]) {
    return null
  }

  // Create a new, sorted and translated, array with the relevant subjects.
  return getTranslatedSubjects([...subjectOptions[examType]], language)
}

export const getDefaultExamSettings = examType => examSettings[examType]

export const imsShortCodeLanguageMappings = {
  [FINNISH]: 'fin',
  [SWEDISH]: 'swe',
  [ENGLISH]: 'eng',
}
export const prioritizedImsShortCodeLanguages = [
  imsShortCodeLanguageMappings[FINNISH],
  imsShortCodeLanguageMappings[SWEDISH],
  imsShortCodeLanguageMappings[ENGLISH],
]

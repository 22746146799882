/* eslint-disable import/prefer-default-export */

import {
  MATRICULATION_EXAM_TYPE,
  VOTE,
  OTHER_EXAM_TYPE,
} from '../../../constants/definitions/exams'

export const getCookiesDisabledText = ({ exam_type, forceYleTunnus }) => {
  switch (exam_type) {
    case VOTE:
      return 'login-required-vote-text'
    case MATRICULATION_EXAM_TYPE:
      return 'login-recommended-text'
    case OTHER_EXAM_TYPE:
      return forceYleTunnus ? 'login-required-other-text' : 'login-recommended-text'
    default:
      return undefined
  }
}

/* eslint-disable max-lines */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { equals } from 'ramda'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { withTranslation } from 'react-i18next'
import Button from '../../elements/Button/Button.tsx'
import {
  DEFAULT_EXAM_LANGUAGE,
  MATRICULATION_EXAM_TYPE,
  OTHER_EXAM_TYPE,
  POLL,
  REVIEW_ONLY_EXAM_TYPE,
  VOTE,
} from '../../../constants/definitions/exams'
import { nodeTypes } from '../../../constants/definitions/entities/entities.ts'
import {
  clearValue,
  clearScore,
  submitNode,
  setMaxScore,
  unsubmitNode,
} from '../../../redux/modules/node/actions.ts'
import { getNodeType, getParentNode } from '../../../redux/modules/nodes/selectors'
import { clearCarousel } from '../../../redux/modules/carousel/carousel'
import Notification from '../../elements/Notification/Notification.tsx'
import Tags from '../../elements/Tags/Tags'
import { searchCarouselQuestions } from '../../../redux/modules/questions/questions'
import { setEmbedOptions } from '../../../redux/modules/embed/embed'
import {
  saveAnswersAndFetchStats,
  deleteAnswers,
} from '../../../redux/middleware/questionService/questionService'
import { getExamType, getHideReview } from '../../../redux/modules/exam/selectors'
import { trackEvent, getNodeTrackingString } from '../../../utils/analytics'
import { getCarouselState, getCarouselActiveState } from '../../../redux/modules/carousel/selectors'
import { getEmbedOptions } from '../../../redux/modules/embed/selectors'
import * as ACTIONS from '../../../constants/definitions/trackingActions'
import './node-review.scss'
import { getDefaultCarouselSelection } from '../../../redux/modules/defaultCarousel/selectors'
import { getQuestionAnswersSaveStatus } from '../../../redux/modules/questions/selectors'
import { isInEditor, isInPreview } from '../../../utils/common'
import { getSeriesClassification } from '../../../redux/modules/series/selectors'
import NodeReviewScore from '../NodeReviewScore/NodeReviewScore'
import ConfirmDialogManager from '../../../confirmDialog/ConfirmDialogManager.ts'
import * as RichText from '../../misc/RichTextContent/RichTextContentHelpers'
import { AutoSubmitNodeTypes } from '../../../constants/definitions/entities/types.ts'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'
import SpacedGroup from '../../elements/SpacedGroup/SpacedGroup.tsx'

// Declare our default transition
const ScoringTransition = ({ isDisabled, ...props }) => {
  const className = 'yo-node-review'
  if (isDisabled) {
    return <div className={className} />
  }
  return <CSSTransition {...props} classNames={className} timeout={{ enter: 120, exit: 120 }} />
}

const getTextKeys = (examType, nodeTypeId) => {
  switch (examType) {
    case OTHER_EXAM_TYPE:
      return { review: 'review_other', clear: 'clear' }
    case VOTE:
      return {
        review:
          nodeTypeId === nodeTypes.SCORED_VOTE_QUESTION.id ? 'review_scored_vote' : 'review_vote',
        clear: 'clear',
      }
    default:
      return { review: 'review', clear: 'clear' }
  }
}

/**
 * Component to handle review (showing feedback and/or scoring) of a single question.
 */
class NodeReview extends React.PureComponent {
  /**
   * When the option "Hide review buttons and auto submit" setting is enabled for the exam,
   * this function takes care of the redirect to the next question when user has answered.
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { node, hideReviewButtons, onClickShow } = this.props

    if (AutoSubmitNodeTypes.includes(node.nodeTypeId)) {
      if (node.completed && hideReviewButtons && !node.submitted) {
        onClickShow()
      }
    }
  }

  onClickOpenCarousel = (e, tag) => {
    const { rootElementId, exam, t, examType } = this.props

    e.preventDefault()

    const lang = exam.language || DEFAULT_EXAM_LANGUAGE
    const mountExam = window.confirm(
      t('confirm-mount-carousel', { tag: tag.title[lang.substr(0, 2)] }),
    )
    const embedOptions = {
      examType,
      tags: [tag.id, ...tag.alternativeIds],
      lang,
      scrollIntoView: true,
    }

    if (mountExam && window.tehtavaApp && window.tehtavaApp.mountCarousel) {
      this.clearCarousel()
      this.searchCarouselQuestions(embedOptions)
      this.setEmbedOptions(embedOptions)
      window.tehtavaApp.mountCarousel(document.getElementById(rootElementId), embedOptions)
    }
  }

  setEmbedOptions = embedOptions => {
    const { dispatch } = this.props
    dispatch(setEmbedOptions(embedOptions))
  }

  searchCarouselQuestions = embedOptions => {
    const { dispatch } = this.props
    searchCarouselQuestions(embedOptions)(dispatch)
  }

  clearCarousel = () => {
    const { dispatch } = this.props
    dispatch(clearCarousel())
  }

  render() {
    const {
      disableClearButton,
      disableReviewButton,
      exam,
      feedback,
      feedbackLink,
      hasReview,
      hideReviewButtons,
      node,
      reviewable,
      answerIsSubmitted,
      t,
      tags,
      yleTunnus,
      options,
      onClickShow,
      onClickClear,
      onClickEdit,
      seriesHideStats,
      examType,
      nodeType,
    } = this.props
    if (!hasReview || examType === POLL) {
      return null
    }

    // Put the node object in a format that the Tags component understands.
    // This is the same format as we would get from state.entities.nodes,
    // but obviously contains only one node instead of all nodes of an Exam.
    const nodeUnderReview = { [node.id]: node }
    const isMatriculationExam = examType === MATRICULATION_EXAM_TYPE
    const optionFeedback =
      options && node.value
        ? options
            .map(option => (node.value[option.id] ? option.feedback : null))
            .filter(feedback => !RichText.isEmpty(feedback))
        : []
    const hasOptionFeedback = optionFeedback.length > 0

    // Feedback should be shown whenever it is present (for all question types)
    // and always for essay questions on matriculation exam.
    // This is because we need to show something to the users when
    // they try to review an essay question.
    const showFeedback =
      !RichText.isEmpty(feedback) ||
      hasOptionFeedback ||
      (node.nodeTypeId === nodeTypes.ESSAY_QUESTION.id && isMatriculationExam)

    const showStatsDisclaimer =
      !seriesHideStats &&
      !exam.hide_statistics &&
      node.statistics &&
      node.nodeTypeId === nodeTypes.VOTE_QUESTION.id &&
      yleTunnus.userId

    // Either show the feedback, or a generic explanation for the reason no feedback can be shown
    // Also for matriculation exams feedback should be at least 10 characters
    let questionFeedback
    if (isMatriculationExam) {
      questionFeedback =
        (Boolean(feedback) && feedback.length > 10) || hasOptionFeedback
          ? feedback
          : t('feedback-missing')
    } else {
      questionFeedback = feedback || ''
    }

    const isVote = examType === VOTE
    const isReviewOnly = examType === REVIEW_ONLY_EXAM_TYPE
    const textKeys = getTextKeys(examType, node.nodeTypeId)
    const noScoring = !nodeType.scoring
    const shouldHideScore = exam.hideScores || isReviewOnly || noScoring
    const hasTags = tags.length > 0
    const showScore = !shouldHideScore
    const showNotification = showFeedback || showScore

    return (
      <div className="yo-node-review" data-testid="node-review">
        {showStatsDisclaimer && answerIsSubmitted && (
          <div className="yo-node-review__tunnus-disclaimer">{t('tunnus-disclaimer')}</div>
        )}
        <TransitionGroup>
          {answerIsSubmitted && showNotification && (
            <ScoringTransition isDisabled={false}>
              <Notification>
                <div className="yo-node-review-notification-content">
                  {showFeedback && (
                    <div className="yo-node-review-notification-feedback">
                      {node.nodeTypeId === nodeTypes.CUSTOMIZED_FEEDBACK.id ||
                      node.nodeTypeId === nodeTypes.POLL_CUSTOMIZED_FEEDBACK.id ||
                      node.nodeTypeId === nodeTypes.ARCHTYPE_CUSTOMIZED_FEEDBACK.id ? (
                        <RichTextContent content={optionFeedback.join('\n')} />
                      ) : (
                        ''
                      )}
                      <RichTextContent content={questionFeedback} />
                    </div>
                  )}
                  {showScore && <NodeReviewScore node={node} />}
                </div>
              </Notification>
            </ScoringTransition>
          )}
          {answerIsSubmitted && (!isVote || !exam.hide_statistics) && (
            <ScoringTransition isDisabled={!showFeedback && shouldHideScore && !hasTags}>
              <div>
                {hasTags && (
                  <div className="yo-node-review__tags">
                    <span className="yo-node-review__tag-title">{t('tags')}</span>
                    <Tags
                      nodes={nodeUnderReview}
                      onClickOpenCarousel={this.onClickOpenCarousel}
                      readOnly
                      secondary
                    />
                  </div>
                )}
              </div>
            </ScoringTransition>
          )}
        </TransitionGroup>
        <SpacedGroup center topSpacing wrap>
          {reviewable && !hideReviewButtons && !isReviewOnly && (
            <Button
              attributes={{ 'data-testid': 'button-review' }}
              isDisabled={disableReviewButton}
              onClick={onClickShow}
              size="sm"
              text={t(textKeys.review)}
            />
          )}
          {reviewable && !hideReviewButtons && !isVote && (
            <Button
              attributes={{ 'data-testid': 'button-review-clear' }}
              isDisabled={disableClearButton}
              onClick={onClickClear}
              size="sm"
              text={t(textKeys.clear)}
              variant="secondary"
            />
          )}
          {reviewable && !hideReviewButtons && node.submitted && !isVote && (
            <Button onClick={onClickEdit} size="xs" text={t('edit-answer')} variant="text" />
          )}
          {feedbackLink && isMatriculationExam && (
            <a
              className="yo-node-review__feedback-link"
              href={feedbackLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('report-a-problem')}
            </a>
          )}
        </SpacedGroup>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { node } = ownProps
  const { feedback, submitted, value, tags } = node
  const nodeType = getNodeType(state, node.nodeTypeId)
  const examId = state.loadState.nodeId || node.examId
  const examType = getExamType(state)

  let feedbackLink
  const { yleTehtavaApp } = window

  if (yleTehtavaApp && yleTehtavaApp.getFeedbackPageInfo) {
    const feedbackPageInfo = yleTehtavaApp.getFeedbackPageInfo(examId, node.id, examType)

    if (feedbackPageInfo && feedbackPageInfo.href) {
      feedbackLink = feedbackPageInfo.href
    }
  }

  return {
    // Disable clear button if node has no value or it hasn't been submitted.
    disableClearButton: !(submitted === true || value),
    // Disable review button if node is already submitted or has no value.
    disableReviewButton: submitted === true || !value || equals(value, {}),
    exam: node.examClassification || state.exam,
    examType,
    nodeType,
    feedback,
    feedbackLink,
    // If feedback or scoring is set on node it can be reviewed.
    hasReview: nodeType.feedback === true || nodeType.scoring === true,
    hideReviewButtons: getHideReview(state),
    // Whether to show review related buttons for the question.
    reviewable: nodeType.reviewable === true,
    rootElementId: state.embed.rootElementId,
    // Show review if the node is submitted.
    answerIsSubmitted: submitted === true,
    tags,
    yleTunnus: state.yleTunnus,
    carousel: getCarouselState(state),
    embedOptions: getEmbedOptions(state),
    isCarouselActive: getCarouselActiveState(state),
    parentNode: getParentNode(state, node.id),
    node,
    defaultCarouselSelection: getDefaultCarouselSelection(state),
    shouldSaveAnswers: getQuestionAnswersSaveStatus(state, ownProps.location),
    seriesHideStats: getSeriesClassification(state).hideStatistics,
  }
}

const handleTracking = (state, action) => {
  const { carousel, isCarouselActive, embedOptions, parentNode, node, examType } = state
  const { defaultCarouselSelection } = state
  const options = isCarouselActive
    ? embedOptions
    : {
        examId: node.examId,
        exam_type: examType,
      }
  const eventStr = getNodeTrackingString({
    node,
    action,
    embedOptions: options,
    isDefaultCarousel: carousel.isDefaultCarousel,
    parent: parentNode,
    defaultCarouselSelection,
  })
  trackEvent(eventStr)
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { options } = ownProps
  const { node, yleTunnus, examType, shouldSaveAnswers } = stateProps

  return {
    ...ownProps,
    ...stateProps,
    onClickEdit: () => {
      dispatch(unsubmitNode(node.id))
    },
    onClickShow: () => {
      dispatch(submitNode(node.id))
      dispatch(setMaxScore(node.id, options))

      if (shouldSaveAnswers) {
        // Save answers and fetch stats after saving
        dispatch(saveAnswersAndFetchStats([node], examType))
      }
      if (!isInEditor(ownProps.location) && !isInPreview(ownProps.location)) {
        handleTracking(stateProps, ACTIONS.REVIEW)
      }
    },
    onClickClear: async () => {
      // Display a confirmation dialog for the user before deleting the exam
      const clearConfirmed = await ConfirmDialogManager.show(ownProps.t('confirm-clear'))
      if (clearConfirmed) {
        // Delete answer from database if user is logged on yle-tunnus
        if (yleTunnus.userId && node.submitted) {
          dispatch(deleteAnswers([node.id]))
        }
        // Disable showing of feedback for the node and clear values submitted by the user.
        dispatch(unsubmitNode(node.id))
        dispatch(clearValue(node.id))
        dispatch(clearScore(node.id))
        dispatch(setMaxScore(node.id, options))
        if (!isInEditor(ownProps.location) && !isInPreview(ownProps.location)) {
          handleTracking(stateProps, ACTIONS.CLEAR)
        }
      }
    },
  }
}
export default withTranslation(['NodeReview'])(
  withRouter(connect(mapStateToProps, null, mergeProps)(NodeReview)),
)

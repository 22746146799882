import React from 'react'
import { withTranslation } from 'react-i18next'
import './LoginWarning.scss'
import { Notification } from '@yleisradio/yds-components-react'
import Login from './Login'

const LoginWarning = ({ ...props }): React.ReactElement => {
  return (
    <div className="login-warning">
      <Notification
        isMultiline
        level="info"
        title={props.t('yle-tunnus-login-required')}
        variant="primary"
      >
        <Login loginAppearance="link" />
      </Notification>
    </div>
  )
}

export default withTranslation(['YleTunnus'])(LoginWarning)

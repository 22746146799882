import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Button from '../../elements/Button/Button.tsx'
import YleTunnusInitials from './YleTunnusInitials'
import { getExamState } from '../../../redux/modules/exam/selectors'
import { ARCHTYPE_TEST, POLL, MATRICULATION_EXAM_TYPE } from '../../../constants/definitions/exams'
import LoginIcon from '../../icons/YleTunnusLogin'
import { isCookiesEnabled } from '../../../utils/common'
import './login.scss'
import { getCookiesDisabledText } from './cookies'
import { onPressLogin } from '../../../webviewApi/actions.ts'

const onTunnusSdkLogin = () => window.yleTehtavaApp.yleTunnus.login()
const onWebviewLogin = dispatch => dispatch(onPressLogin())
const onClickLogin = dispatch => {
  if (IS_WEBVIEW_EMBED) {
    onWebviewLogin(dispatch)
  } else {
    onTunnusSdkLogin()
  }
}

export const LoginButton = ({ t, classNames = [], dispatch, ...props }) => (
  <Button
    classNames={['yo-tunnus-button', ...classNames]}
    onClick={() => onClickLogin(dispatch)}
    size="sm"
    text={t('YleTunnus:login')}
    {...props}
  />
)

const MatriculationExamLogin = ({ loggedIn, t, dispatch }) => {
  return loggedIn ? (
    <div className="yo-tunnus-wrapper">
      <div className="yo-tunnus-info">{t('logged-in-text-matriculation-exam')}</div>
      <YleTunnusInitials />
    </div>
  ) : (
    <div className="yo-tunnus-matriculation-login">
      <a
        onClick={() => onClickLogin(dispatch)}
        onKeyDown={e => e.key === 'Enter' && onClickLogin(dispatch)}
        role="button"
        tabIndex={0}
      >
        {t('YleTunnus:logged-out-text-matriculation-exam-1')}
      </a>
      {t('YleTunnus:logged-out-text-matriculation-exam-2')}
    </div>
  )
}

/*
  This component handles login to yleTunnus.
*/
class Login extends React.Component {
  hasLoginContent() {
    const { exam_type, forceYletunnus, disable_saving } = this.props

    const hasLogin = !(exam_type === ARCHTYPE_TEST || exam_type === POLL)

    return window.yleTunnus &&
      window.yleTehtavaApp &&
      window.yleTehtavaApp.yleTunnus &&
      hasLogin &&
      forceYletunnus
      ? true
      : !disable_saving
  }

  render() {
    const { t, loggedIn, exam_type, forceYleTunnus, dispatch, loginAppearance } = this.props

    if (!this.hasLoginContent()) {
      return null
    }

    if (exam_type === MATRICULATION_EXAM_TYPE) {
      return <MatriculationExamLogin dispatch={dispatch} loggedIn={loggedIn} t={t} />
    }

    const cookiesDisabledTextKey =
      !isCookiesEnabled() && getCookiesDisabledText({ exam_type, forceYleTunnus })

    if (loginAppearance === 'link') {
      return (
        <Link
          className="yo-login-link"
          onClick={e => {
            e.preventDefault()
            onClickLogin(dispatch)
          }}
          to="#"
        >
          {t('YleTunnus:login-long')}
        </Link>
      )
    }

    return (
      <div className="yo-tunnus-wrapper">
        {cookiesDisabledTextKey && (
          <span className="yo-tunnus-login__span">{t(cookiesDisabledTextKey)}</span>
        )}
        {loggedIn ? (
          <>
            <div className="yo-tunnus-info">{t('logged-in-text')}</div>
            <YleTunnusInitials />
          </>
        ) : (
          <button
            className="yo-tunnus-login-button"
            disabled={cookiesDisabledTextKey}
            onClick={() => onClickLogin(dispatch)}
            type="button"
          >
            <div className="yo-tunnus-login-button-title">{t('YleTunnus:login')}</div>
            <LoginIcon height={24} width={24} />
          </button>
        )}
      </div>
    )
  }
}

Login.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  disable_saving: PropTypes.bool,
  exam_type: PropTypes.string.isRequired,
  forceYletunnus: PropTypes.bool,
  loginAppearance: PropTypes.oneOf(['button', 'link']),
}

Login.defaultProps = {
  disable_saving: false,
  forceYletunnus: false,
  loginAppearance: 'button',
}

const mapStateToProps = state => {
  const { exam_type, forceYletunnus, disable_saving } = getExamState(state)
  return {
    loggedIn: Boolean(state.yleTunnus.userId),
    disable_saving,
    exam_type,
    forceYletunnus,
  }
}

export default withTranslation(['YleTunnus'])(connect(mapStateToProps)(Login))

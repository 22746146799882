/* eslint-disable max-lines */
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import {
  fetchPublishedExam,
  fetchExamAnswers,
  fetchAverageScores,
} from '../../../redux/middleware/examService/examService'
import {
  saveAnswersAndFetchStats,
  deleteAnswers,
} from '../../../redux/middleware/questionService/questionService'
import { getLoadedNodeId, getErrorMessage } from '../../../redux/modules/loadState/selectors'
import { setMaxScores } from '../../../redux/modules/nodes/nodes'
import {
  submitNode,
  unsubmitNode,
  clearValue,
  clearScore,
} from '../../../redux/modules/node/actions.ts'
import { getAllNodes, getNode } from '../../../redux/modules/nodes/selectors'
import { getExamSubmitted, getIsOpen } from '../../../redux/modules/exam/selectors'
import Button from '../../elements/Button/Button.tsx'
import Notification from '../../elements/Notification/Notification.tsx'
import ExamReviewContainer from '../../exam/ExamReview/ExamReviewContainer.tsx'
import NodeContainer from '../../node/NodeContainer'
import { setRootElementId, setEmbedOptions } from '../../../redux/modules/embed/embed'
import Login from '../YleTunnus/Login'
import './widget.scss'
import {
  fetchFeedbackStatsByExamUuid,
  fetchFeedbackStatSums,
} from '../../../redux/modules/statistics/statistics'
import { scrollToView, isInEditor, isInPreview } from '../../../utils/common'
import SeriesInfoContainer from '../../series/SeriesInfo/SeriesInfoContainer'
import { ARCHTYPE_TEST, OTHER_EXAM_TYPE, POLL, VOTE } from '../../../constants/definitions/exams'
import { trackEvent, getExamTrackingString } from '../../../utils/analytics'
import * as ACTIONS from '../../../constants/definitions/trackingActions'
import { getQuestionAnswersSaveStatus } from '../../../redux/modules/questions/selectors'
import usePollStats from '../../../hooks/usePollStats/usePollStats'
import { setLoginRequired } from '../../../webviewApi/actions.ts'
import {
  DARK_MODE_AUTO,
  DARK_MODE_ON,
  DARK_MODE_OFF,
} from '../../../constants/definitions/darkModeParams'
import ConfirmDialog from '../../../confirmDialog/ConfirmDialog.tsx'
import { useConfirmDialog } from '../../../confirmDialog/ConfirmDialogManager.ts'
import { findSpecialQuestionIdsWithSumResults } from '../../../utils/examHelpers'
import LoginWarning from '../YleTunnus/LoginWarning.tsx'
import SpacedGroup from '../../elements/SpacedGroup/SpacedGroup.tsx'

const Widget = ({
  clearNodes,
  errorMessage,
  inEditor,
  rootElementId,
  setRootElementId,
  submitNodes,
  t,
  uuid,
  exam,
  yleTunnus,
  examSubmitted,
  isEmbedded,
  shouldRender,
  darkMode,
  embedOptions,
  setEmbedOptions,
  handleTracking,
  examLoaded,
  fetchExamAnswers,
  fetchFeedbackStats,
  fetchPublishedExam,
  dispatch,
  nodes,
  isPoll,
  isVote,
  examIsOpen,
  isDefaultCarousel,
}) => {
  const confirmClearNodes = useConfirmDialog(t('Widget:confirm-clear'), clearNodes)

  useEffect(() => {
    if (isEmbedded) {
      setRootElementId()
      fetchPublishedExam()
      if (yleTunnus.userId) {
        fetchExamAnswers()
      }
    }
    if (embedOptions) {
      setEmbedOptions(embedOptions)

      // Scroll into view on embedded widget
      if (embedOptions.scrollIntoView) {
        scrollToView(rootElementId)
      }
    }
  }, [])

  useEffect(handleTracking, [examLoaded])
  useEffect(fetchExamAnswers, [yleTunnus])
  useEffect(fetchFeedbackStats, [examLoaded, yleTunnus])

  usePollStats({
    uuids: Object.keys(nodes),
    loaded: examLoaded,
    dispatch,
    isPoll,
  })

  // FIXME: you can use getLoggedInState(state) instead
  const hasLoggedIn = Boolean(yleTunnus.userId)

  const requireLogin = isEmbedded && exam.isOpen && exam.forceYletunnus && !hasLoggedIn
  const shouldHideLoginBar = !exam.isOpen || isPoll || exam.disable_saving || isDefaultCarousel

  useEffect(() => {
    if (IS_WEBVIEW_EMBED) {
      dispatch(
        setLoginRequired({
          required: Boolean(requireLogin && !hasLoggedIn),
          hidden: Boolean(hasLoggedIn || shouldHideLoginBar),
        }),
      )
    }
  }, [requireLogin, shouldHideLoginBar, hasLoggedIn])

  if (!shouldRender) {
    return null
  }
  // DO NOT ADD HOOKS BELOW THIS LINE

  const containerClassNames = classnames('yo-widget', 'yo-tehtava', {
    'yo-widget--editing': inEditor,
    'yo-widget--webview': IS_WEBVIEW_EMBED,
    'yo-tehtava--auto-dark': darkMode === DARK_MODE_AUTO,
    'yo-tehtava--force-dark': darkMode === DARK_MODE_ON,
  })

  if (errorMessage) {
    return (
      <div className={containerClassNames}>
        <Notification isMultiline level="error" title={t('error:error')}>
          {t(`error:${errorMessage}`)}
        </Notification>
      </div>
    )
  }

  let review_text_key = 'review'
  let clear_text_key = 'clear'
  if (exam.exam_type === OTHER_EXAM_TYPE) {
    review_text_key = 'review_other'
    clear_text_key = 'clear_other'
  } else if (exam.exam_type === VOTE) {
    review_text_key = 'review_vote'
  }

  const actionButtons =
    exam.exam_type !== ARCHTYPE_TEST && !isPoll ? (
      <SpacedGroup classNames={['yo-widget__actions-buttons']}>
        {((exam.exam_type === VOTE && exam.hideReviewButtons) ||
          (exam.exam_type !== VOTE && !examSubmitted)) && (
          <Button
            attributes={{ 'data-testid': 'review-all' }}
            isDisabled={examSubmitted || !examIsOpen}
            onClick={submitNodes}
            size="md"
            text={t(`Widget:${review_text_key}`)}
          />
        )}
        {!isVote && (
          <Button
            attributes={{ 'data-testid': 'review-clear-all' }}
            onClick={confirmClearNodes}
            size="md"
            text={t(`Widget:${clear_text_key}`)}
            variant="secondary"
          />
        )}
      </SpacedGroup>
    ) : (
      undefined
    )

  return (
    <div className={containerClassNames}>
      {rootElementId && (
        <div>
          <div
            className={classnames([
              'yo-widget-login-wrapper',
              { 'yo-widget-login-wrapper--hidden': shouldHideLoginBar },
            ])}
          >
            <Login />
          </div>
        </div>
      )}
      <div className={classnames({ 'yo-widget--blurred': requireLogin })}>
        <NodeContainer editing={inEditor} id={uuid} />
        <ExamReviewContainer examType={exam.exam_type} />
        {isVote && !examIsOpen && (
          <span className="yo-widget__exam-ended">{t('Widget:ended')}</span>
        )}
        {actionButtons}
        {isEmbedded && (
          <>
            <SeriesInfoContainer collapsible darkMode={darkMode} seriesIds={exam.series_uuids} />
            <ConfirmDialog />
          </>
        )}
      </div>
      {requireLogin && <LoginWarning />}
    </div>
  )
}

Widget.propTypes = {
  uuid: PropTypes.string.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const { uuid, match, embedOptions } = ownProps
  let routerId

  if (ownProps.routerId) {
    ;({ routerId } = ownProps)
  }
  if (match && match.params) {
    routerId = match.params.id
  }

  const { loadState, exam } = state
  const inEditor = isInEditor(ownProps.location)
  const inPreview = isInPreview(ownProps.location)
  const rootNode = getNode(state, getLoadedNodeId(state))
  const errorMessage = getErrorMessage(state)

  return {
    // errorMessage e.g. "exam is not published yet"
    errorMessage,
    exam,
    examLoaded: state.loadState.loaded,
    inEditor,
    inPreview,
    // Dark mode should be 'auto' inside Tehtava editor
    darkMode: embedOptions?.darkMode || (inEditor || inPreview ? DARK_MODE_AUTO : DARK_MODE_OFF),
    nodes: getAllNodes(state),
    shouldRender: (loadState.nodeId && loadState.loaded) || errorMessage,
    // Use id from router and fallback to getting it from props.
    uuid: routerId || uuid,
    options: state.entities.options,
    yleTunnus: state.yleTunnus,
    examSubmitted: getExamSubmitted(state),
    rootNode,
    isEmbedded: !inEditor && !inPreview,
    shouldSaveAnswers: getQuestionAnswersSaveStatus(state, ownProps.location),
    isPoll: exam.exam_type === POLL,
    isVote: exam.exam_type === VOTE,
    examIsOpen: getIsOpen(state),
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    nodes,
    yleTunnus,
    options,
    uuid,
    exam,
    rootNode,
    shouldSaveAnswers,
    examLoaded,
    isEmbedded,
    isPoll,
  } = stateProps
  const { dispatch } = dispatchProps
  return {
    ...ownProps,
    ...stateProps,
    fetchPublishedExam: () => dispatch(fetchPublishedExam(uuid)),
    fetchExamAnswers: () => {
      if (isEmbedded && yleTunnus.userId && !isPoll) {
        dispatch(fetchExamAnswers(uuid))
      }
    },
    submitNodes: () => {
      Object.keys(nodes).forEach(id => dispatch(submitNode(id)))
      dispatch(setMaxScores(options))
      if (shouldSaveAnswers) {
        dispatch(saveAnswersAndFetchStats(Object.values(nodes), exam.exam_type))
      }
      const eventStr = getExamTrackingString(exam, rootNode, ACTIONS.REVIEW)
      trackEvent(eventStr)
    },
    setRootElementId: () => {
      dispatch(setRootElementId(ownProps.rootElementId))
    },
    fetchFeedbackStats: () => {
      if (
        examLoaded &&
        (yleTunnus.userId || !exam.isOpen) &&
        !exam.disable_saving &&
        !exam.hide_statistics &&
        !isPoll
      ) {
        dispatch(fetchFeedbackStatsByExamUuid(uuid))
        dispatch(fetchAverageScores(uuid))
        const questionIdsWithSumResults = findSpecialQuestionIdsWithSumResults(nodes)
        if (questionIdsWithSumResults.length) {
          dispatch(fetchFeedbackStatSums(questionIdsWithSumResults))
        }
      }
    },
    clearNodes: async () => {
      const nodeIds = Object.keys(nodes)

      nodeIds.forEach(id => {
        dispatch(unsubmitNode(id))
        dispatch(clearValue(id))
        dispatch(clearScore(id))
      })
      dispatch(setMaxScores())

      // Delete answer from database if user is logged on yle-tunnus
      if (yleTunnus.userId) {
        dispatch(deleteAnswers(nodeIds))
      }
      const eventStr = getExamTrackingString(exam, rootNode, ACTIONS.CLEAR)
      trackEvent(eventStr)
    },
    setEmbedOptions: embedOptions => {
      dispatch(setEmbedOptions(embedOptions))
    },
    handleTracking: () => {
      if (examLoaded) {
        const eventStr = getExamTrackingString(exam, rootNode, ACTIONS.VIEW)
        trackEvent(eventStr)
      }
    },
    dispatch,
  }
}

export default withTranslation()(connect(mapStateToProps, null, mergeProps)(Widget))

import React from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import { MATRICULATION_EXAM_TYPE } from '../../../constants/definitions/exams'
import './disclaimer.scss'
import { getExamState } from '../../../redux/modules/exam/selectors'
import Notification from '../Notification/Notification'

const ExamDisclaimer = ({ exam, t }) => {
  const examType = exam.exam_type
  const year = parseInt(exam.year, 10)

  return (
    examType === MATRICULATION_EXAM_TYPE && (
      <div className={classnames('yo-disclaimer')} data-testid="yo-disclaimer">
        {year >= 2006 && year <= 2012 && (
          <Notification className="yo-disclaimer__no-answers-notification" hideIcon={false}>
            {t('no-ytl-answers')}
          </Notification>
        )}
        <Notification hideIcon={false}>{t('common:ytl-notice')}</Notification>
      </div>
    )
  )
}

export const NodeDisclaimer = ({ disclaimer }) => <div className="yo-disclaimer">{disclaimer}</div>

const mapStateToProps = state => ({
  exam: getExamState(state),
})

export default withTranslation(['Disclaimer'])(connect(mapStateToProps)(ExamDisclaimer))

import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import './yle-tunnus-initials.scss'

const YleTunnusInitials = props => {
  const { yleTunnus, t } = props

  if (!yleTunnus.userId) {
    return null
  }

  return (
    <div className="yo-yletunnus-menu__container">
      <div className="yo-yletunnus-menu__initials-container" type="button">
        <div
          aria-label={`${t('logged-in-text-long')}${
            yleTunnus.initials ? t('as-initials') + yleTunnus.initials : ''
          }`}
          className="yo-yletunnus-menu__initials"
        >
          {yleTunnus.initials || 'Yle'}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  yleTunnus: state.yleTunnus,
})

export default withTranslation(['YleTunnus'])(connect(mapStateToProps)(YleTunnusInitials))
